<template>
    <div class="report">
        <el-card>
            <div slot="header" class="clearfix">
                <span>统计报表</span>
            </div>
            <el-table
                    :data="tableData"
                    style="width: 100%">
                <el-table-column prop="0">
                </el-table-column>
                <el-table-column prop="1">
                </el-table-column>
                <el-table-column prop="2">
                </el-table-column>
                <el-table-column prop="3">
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
  import {Loading} from 'element-ui';
  import {apiAnalysisData} from "../../api/analysis";

  export default {
    name: "report",
    data () {
      return {
        tableData: []
      }
    },
    mounted () {
      this.getData()
      // if (this.$store.state.curAccountId.length === 0) {
      //   return false;
      // }
      // let accountId = JSON.stringify(this.$store.state.curAccountId);
      // let loadingInstance = Loading.service({target: '.h-chart'});
      // apiAnalysisData({
      //   'account': accountId,
      //   'type': 'report',
      //   'analysisType': this.$store.state.analysisType
      // }).then(response => {
      //   this.tableData = response.data;
      //   loadingInstance.close();
      // })
    },
    methods: {
      async getData () {
        if (this.$store.state.curAccountId.length === 0) {
          return false;
        }
        let loadingInstance = Loading.service({target: '.report'});
        const params = {
          a_id: this.$store.state.curAccountId.join(','),
          type: this.$store.state.analysisType,
          b_date: this.$store.state.dateRange.b_date,
        e_date: this.$store.state.dateRange.e_date
        }
        let res = await apiAnalysisData('statReport', params)
        if (res.data.code === 0 && res.data.data) {
          const resData = JSON.parse(JSON.stringify(res.data.data))
          this.tableData = [
            ['当日权益',resData.f1,'累计净利润', resData.f2],
            ['当日净利润',resData.f3,'当日手续费', resData.f4],
            ['累计手续费',resData.f5,'净出入金', resData.f6],
            ['累计入金',resData.f7,'累计出金', resData.f8],
            ['最大使用资金',resData.f9,'日均隔夜仓位', resData.f10],
            ['日均成交额',resData.f11,'日均权益', resData.f12],
            ['交易天数',resData.f13,'最大空仓天数', resData.f14],
            ['盈利天数',resData.f15,'亏损天数', resData.f16],
            ['最大连续盈利天数',resData.f17,'最大连续亏损天数', resData.f18],
            ['日胜率',(resData.f19 * 100).toFixed(2) + '%','周胜率', (resData.f20 * 100).toFixed(2) + '%'],
            ['单日最大盈利',resData.f21,'单日最大亏损', resData.f22],
            ['当日收益率',(resData.f23 * 100).toFixed(2) + '%','累计收益率', (resData.f24 * 100).toFixed(2) + '%'],
            ['年化收益率',(resData.f25 * 100).toFixed(2) + '%','最大回撤率', resData.f26],
            ['夏普比率',resData.f27,'收益回撤比', resData.f28],
          ]
          loadingInstance.close();
        }
      }
    }
  }
</script>

<style lang="scss">
    .report {
        .el-card {
            border: 0;

            .el-card__header {
                background: #1777FF;
                color: #ffffff;
            }
        }

        .el-table__header-wrapper {
            display: none;
        }
    }
</style>
